import { useApp } from 'components/app';
import { IMediaUpload } from 'interface/IMedia';
import React, { useEffect, useRef } from 'react';
import { doesItFit, getImageSize, checkRatio } from 'utils/mediaRatioUtils';

import { NotifyType } from '../atoms/Toast';
import PulseLoader from 'react-spinners/PulseLoader';

const baseUrlUploaded = 'https://storage.googleapis.com/engage-uploaded-videos/';

type IProps = {
  data: IMediaUpload;
  resolution: [number, number];
};
type IRatioCheck = {
  canUpload: number;
  message: string;
  type: NotifyType;
};
//const maxMediaFileSize = 41943040;
const maxMediaFileSize = 52428800 * 2;

const PreviewMediaCard = ({ data, resolution }: IProps) => {
  const [file, setFile] = React.useState<IMediaUpload>(data);
  const [src, setSrc] = React.useState('https://storage.googleapis.com/engage-uploaded-videos/blank_image.png');
  const [response, setResponse] = React.useState<IRatioCheck | null>();
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    verifyMedia();
  }, []);

  useEffect(() => {
    if (response) {
      if (response.canUpload === 0) {
        setResponse(null);
      }
      if (response.canUpload === 1) {
      }
    }

    return () => {};
  }, [response]);

  const verifyMedia = () => {
    const reader = new FileReader();
    if (data.file.type && data.file.type.match('image')) {
      reader.onload = e => {
        if (data.file.size > maxMediaFileSize) {
        } else {
          if (reader && reader.result)
            getImageSize(reader.result, (width: any, height: any) => {
              const inputImage = {
                width,
                height,
              };
              const targetVideo = {
                width: resolution[0],
                height: resolution[1],
              };
              const isValid = doesItFit(inputImage, targetVideo, 3);

              let dataRatio = checkRatio(file.file.name, isValid, `${resolution[0]}x${resolution[1]}`);
              setResponse(dataRatio);
            });
        }
        if (reader.result) {
          setSrc(reader.result as string);
        }
      };
      reader.readAsDataURL(file.file);
    } else if (data.type === 'draft') {
      const ext = data.oldName.split('.');

      if (data.oldUid) setSrc(`${baseUrlUploaded}${data.oldUid}.${ext[ext.length - 1]}`);
      else setSrc(`${baseUrlUploaded}${data.uid}.${ext[ext.length - 1]}`);
    } else if (data.type === 'draftvideo' || data.type === 'uploaded') {
      console.log('data', data);
      const ext = data.oldName.split('.');
      const video = document.createElement('video');
      video.preload = 'metadata';

      if (data.oldUid) setSrc(`${baseUrlUploaded}${data.oldUid}.${ext[ext.length - 1]}`);
      else setSrc(`${baseUrlUploaded}${data.uid}.${ext[ext.length - 1]}`);
      video.muted = true;
      video.playsInline = true;
      video.currentTime = 1;
      video.play();
    } else if (data.file.type === 'remote') {
      const video = document.createElement('video');

      video.preload = 'metadata';
      video.src = file.location;
      video.muted = true;
      video.playsInline = true;
      video.play();
    } else {
      reader.onload = () => {
        const blob = new Blob([reader.result as string], { type: data.file.type });
        const url = URL.createObjectURL(blob);
        const video: HTMLVideoElement = document.createElement('video');
        const timeupdate = () => {
          if (snapImage(video, url)) {
            video.removeEventListener('timeupdate', timeupdate);
            video.pause();
          }
        };
        video.addEventListener('timeupdate', timeupdate);
        video.preload = 'metadata';
        video.className = 'hidden';
        video.src = url;
        video.muted = true;
        video.playsInline = true;
        video.currentTime = 1;

        video.play();
      };
      reader.readAsArrayBuffer(data.file);
      return true;
    }
  };

  const snapImage = (video: HTMLVideoElement, url: string): boolean => {
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    let context = canvas.getContext('2d');
    if (context) context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const image = canvas.toDataURL('image/png');
    const success = image.length > 100000;

    if (success) {
      setSrc(image);
      URL.revokeObjectURL(url);
    }
    return true;
  };

  useEffect(() => {
    if (file.action === 'uploaded' || file.action === 'isuploaded') {
      if ((file.file?.type?.match(/video/) || file.oldType?.match(/video/)) && videoRef.current) {
        videoRef.current.currentTime = 1;
        videoRef.current.pause();
      }
    }
  }, []);
  return (data.action === 'toupload' || data.action === 'isuploaded' || data.type.includes('draft')) &&
    (data.action === 'isuploaded' || data.type.includes('draft')) ? (
    <div>
      {data.oldType.includes('video') ? (
        <video ref={videoRef} src={src} className="preview-image rounded-2xl" muted loop>
          <source src={src} />
        </video>
      ) : (
        <img className="preview-image rounded-2xl" src={src}></img>
      )}
    </div>
  ) : (
    <div className="preview-loader-container">
      <PulseLoader color={'#eeeff0'} size={10} loading={true} />
    </div>
  );
};
export default PreviewMediaCard;
