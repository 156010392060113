import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useIntl, FormattedMessage } from 'react-intl';
import * as Sentry from '@sentry/browser';

import { PublishContext } from 'context/publish/publish.provider';

import { useRouter } from 'next/router';
import { format } from 'date-fns';
import { JWT, TPSAPI } from 'api';
import { gtmCheckout5, gtmAddPaymentInfo, gtmPurchase } from 'api/gtm/gtm';

import { FooterMobile, PublishMapList, Dialog, BreadCrumbs, AddVoucher } from 'components/common/molecules';
import { oneLine } from 'common-tags';
import DataTable from 'react-data-table-component';
import { IScheduler, ISchedulerApiSubmit } from 'interface/IScheduler';
import { Campaigns } from 'api/campaigns';
import { IReviewPrice } from 'interface/IPrice';
import { Button, Input } from 'components/common/atoms';
import numeral from 'numeral';
import { IListLocation } from 'interface/ILocation';
import Dinero from 'dinero.js';
import { IUserOrganization } from 'interface';
import { useApp } from 'components/app';
import { IPublishType } from 'context/publish/publish.reducer';
import ClassicCard from 'components/common/atoms/ClassicCard';

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';
import { TableMobile } from 'components/common/molecules/TableMobile';
import ReactTooltip from 'react-tooltip';
import { DateTime } from 'luxon';
import useOnboardingContext from 'context/OnboardingContext';
import { fetchUserClientData } from '@utils/userData';
import { formatImpressionsToReadable } from '@utils/formatImpressions';
import { usePriceCalculator } from '../../../../hooks/usePriceCalculator';
import { convertSeconds } from '@utils/convertToDays';

type IProps = {
  userCompany: IUserOrganization;
};
type PrimaryButtonProps = {
  buttonText: string;
  className?: string;
  action: () => void;
};

const columns = [
  {
    name: 'Location',
    selector: 'name',
    accessor: 'name',
    sortable: true,
    cell: (props: any) => (
      <div className="cursor-pointer" title={props.name}>
        {props.name}
      </div>
    ),
  },
  {
    name: 'Frequency',
    selector: 'frequency',
    accessor: 'frequency',
    sortable: true,
  },
  {
    name: 'Estimated Run Time',
    selector: 'slots',
    accessor: 'slots',
    sortable: true,

    sortFunction: (a: { slotsNumber: number }, b: { slotsNumber: number }) => {
      if (a.slotsNumber > b.slotsNumber) return 1;
      if (a.slotsNumber < b.slotsNumber) return -1;
      return 0;
    },
  },
  {
    name: 'Est Plays',
    selector: 'plays',
    accessor: 'plays',
    sortable: true,
    sortFunction: (a: { playsNumber: number }, b: { playsNumber: number }) => {
      if (a.playsNumber > b.playsNumber) return 1;
      if (a.playsNumber < b.playsNumber) return -1;
      return 0;
    },
  },

  {
    name: 'Impressions',
    selector: 'reachAdm',
    accessor: 'reachAdm',
    sortable: true,
    sortFunction: (a: { reachAdmNumber: string }, b: { reachAdmNumber: string }) => {
      if (a.reachAdmNumber > b.reachAdmNumber) return 1;
      if (a.reachAdmNumber < b.reachAdmNumber) return -1;
      return 0;
    },
  },
  {
    name: 'Estimated Watch Time',
    selector: 'reach',
    accessor: 'reach',
    sortable: true,
    sortFunction: (a: { reachNumber: string }, b: { reachNumber: string }) => {
      if (a.reachNumber > b.reachNumber) return 1;
      if (a.reachNumber < b.reachNumber) return -1;
      return 0;
    },
  },
  {
    name: 'Price',
    selector: 'price',
    accessor: 'price',
    sortable: true,
    sortFunction: (a: { priceNumber: string }, b: { priceNumber: string }) => {
      if (a.priceNumber > b.priceNumber) return 1;
      if (a.priceNumber < b.priceNumber) return -1;
      return 0;
    },
  },
];

const ReviewPage: React.FC<IProps> = ({ userCompany }: IProps) => {
  const intl = useIntl();
  const router = useRouter();

  const rangeRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const bottomRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  const { publishState, dispatch } = React.useContext(PublishContext);
  const [data, setData] = React.useState<any[]>([]);
  const [loadingPdf, setLoadingPdf] = React.useState<boolean>(false);
  const [loadingShareCampaign, setLoadingShareCampaign] = React.useState<boolean>(false);
  const [loadingSaveCampaign, setLoadingSaveCampaign] = React.useState<boolean>(false);
  const [loadingCsv, setLoadingCsv] = React.useState<boolean>(false);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [showDialogNewUser, setShowDialogNewUser] = React.useState<boolean>(false);
  const [totalLocations, setTotalLocations] = React.useState<number>(0);
  const [totalCircuit, setTotalCircuit] = React.useState<number>(0);
  const [totalHours, setTotalHours] = React.useState<number>(0);
  const [reviewInfo, setReviewInfo] = React.useState<IReviewPrice | null>(null);
  const [viewCredit, setViewCredit] = React.useState<string>('');
  const [credits, setCredits] = React.useState<number>(0);
  const [creditValue, setCreditValue] = React.useState<number>(0);
  const [percentDiscount, setPercentDiscount] = React.useState<number>(0);
  const [reusableFixedDiscount, setReusableFixedDiscount] = React.useState<number>(0);
  const [voucherCode, setVoucherCode] = React.useState<string>('');
  const [clearDiscountErrors, setClearDiscountErrors] = React.useState<number>(0);
  const [user, setUser] = React.useState<IUserOrganization | null>(null);
  const { notify } = useApp();
  const JWTUser = JWT.getJwtUser();
  const { state } = usePriceCalculator({ publishState, dispatch });

  useEffect(() => {
    if (!publishState.name || publishState.industry?.length === 0) {
      router.push('/publish/basic');
    }
    return () => {};
  }, []);

  const {
    setState,
    state: { run, tourActive, stepIndex, steps },
  } = useOnboardingContext();

  useEffect(() => {
    if (stepIndex === 24) {
      if (bottomRef.current && typeof bottomRef.current.scrollIntoView === 'function') {
        bottomRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [stepIndex]);

  useEffect(() => {
    if (tourActive) {
      setState({ run: true, stepIndex: 21, steps, tourActive });
    }
  }, [data]);

  useEffect(() => {
    if (reviewInfo) {
      try {
        gtmAddPaymentInfo(publishState.locations, reviewInfo, userCompany.tpsRegion.currency);
      } catch (error) {
        Sentry.captureException(error);
      }
      gtmCheckout5();
    }
    return () => {};
  }, [reviewInfo]);

  useEffect(() => {
    if (rangeRef.current && credits !== 0) useCredits(publishState.usedCredits / 10000);
  }, [rangeRef.current, credits]);

  const backFn = () => {
    router.push(`/publish/media?campaignId=${publishState.uuid}`);
  };

  const viewData = async () => {
    let items: any = [];
    let totalLocationsTemp = 0;
    let totalSlots = 0;
    let submitData: ISchedulerApiSubmit[] = [];
    if (publishState.schedule)
      for (const item of publishState.schedule) {
        if (item.uuid !== 'global') {
          totalLocationsTemp += item.locations.length;
          totalSlots += item.slots.length;

          submitData.push({
            locations: item.locations,
            slots: item.slots,
          });
        }
      }
    setTotalLocations(totalLocationsTemp);
    setTotalHours(totalSlots);
    const response = await Campaigns.calculatePriceEndPrice(submitData);
    let totalCircuitTemp = 0;
    setReviewInfo(response as IReviewPrice);

    if (publishState.locations)
      for (const item of publishState.locations) {
        let pricePerScreen: IReviewPrice = response as IReviewPrice;

        let [screen] = pricePerScreen?.pricePerScreen?.filter(el => el.uuid === item.location.uuid);
        const foundFormat = publishState.formats?.find(f => f.locations.includes(item.location.uuid));
        totalCircuitTemp += item.location.circuit;
        const plays = getSlots(item) * (3600 / item.pph) * item.location.circuit;
        const duration = foundFormat?.selectedDuration || 10;
        items.push({
          uuid: item.location.uuid,
          name: item.location.n,
          frequency: 3600 / item.pph,
          slots: `${convertSeconds(plays * duration)} `,
          slotsNumber: plays * duration,
          playsNumber: plays,
          plays: formatImpressionsToReadable(plays),
          reachAdm: item.reach ? formatImpressionsToReadable(item.reach) : 'N/A',
          reachAdmNumber: item.reach,
          reach: item.reach ? `${convertSeconds(item.reach * 3)} ` : 'N/A',
          price: screen ? screen.price : '0',
          priceNumber: screen ? Number((screen.price || '0').replace(/[^\d.-]/g, '')) : 0,
          reachNumber: Math.floor(item.reach) * 3,
        });
      }
    setTotalCircuit(totalCircuitTemp);

    setData(items);
    // submisionData()
  };
  useEffect(() => {
    getUser();
    viewData();
    getUserCredit();
    Campaigns.saveCampaignDraft({ ...publishState, maxStep: 6 }).catch(err => console.log(err));

    return () => {};
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        getUser();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const getUser = async () => {
    const User = await TPSAPI.getUserCompanyData();
    setUser(User);
  };

  const getUserCredit = async () => {
    try {
      const result = await TPSAPI.getUserCredit();
      setViewCredit(result.creditValue);
      setCredits(result.credit);
      if (result.credit < publishState.usedCredits) useCredits(0);
    } catch (err) {
      console.log(err);
    }
  };

  const submisionData = async (withAnalytics?: boolean) => {
    const User = await TPSAPI.getUserCompanyData();
    let formData = new FormData();
    const newFormats = [];
    const customMedia = [];
    let allSlots: number[] = [];
    if (publishState.schedule)
      publishState.schedule.forEach(el => {
        allSlots = [...allSlots, ...el.slots];
      });
    let totalReach = 0;
    if (publishState.locations)
      for (const locations of publishState.locations) {
        totalReach += locations.reach;
      }

    const budget = {
      networks: [],
      tlp: reviewInfo?.tlp,
      screens: [],
      total: reviewInfo?.total,
      totalWithCredit: reviewInfo?.total,
      totalWithCreditAndTax: reviewInfo?.total,
      tax: reviewInfo?.vat,
      subTotal: reviewInfo?.subTotal,
      percentDiscount: percentDiscount ? percentDiscount : 0,
      reusableFixedDiscount: reusableFixedDiscount ? reusableFixedDiscount : 0,

      credits: credits,
      totalWithTax: reviewInfo?.total,
      handleFeeVal: reviewInfo?.handleFeeVal,
      handleFee: reviewInfo?.handleFee,
      tlpValue: reviewInfo?.tlpValue,

      usedCredits:
        userCompany.tpsRegion &&
        Dinero({
          amount: publishState.credit,
          precision: 4,
          currency: userCompany.tpsRegion.currency,
        }).toFormat(),

      subtotalWithUsedCredit:
        userCompany.tpsRegion &&
        reviewInfo &&
        Dinero({
          amount: reviewInfo?.totalValue - publishState.usedCredits,
          precision: 4,
          currency: userCompany.tpsRegion.currency,
        }).toFormat(),

      valuePercentDiscount:
        userCompany.tpsRegion &&
        reviewInfo &&
        Dinero({
          amount: Math.ceil((percentDiscount / 100) * (reviewInfo.totalValue - publishState.usedCredits)),
          precision: 4,
          currency: userCompany.tpsRegion.currency,
        }).toFormat(),

      valueReusableFixedDiscount:
        userCompany.tpsRegion &&
        reviewInfo &&
        Dinero({
          amount: Math.ceil(reusableFixedDiscount),
          precision: 4,
          currency: userCompany.tpsRegion.currency,
        }).toFormat(),

      totalWithCalcApplied:
        reviewInfo &&
        Dinero({
          amount:
            Math.ceil(reviewInfo.totalValue - publishState.usedCredits) < 0
              ? 0
              : Math.ceil(
                  reviewInfo.totalValue -
                    publishState.usedCredits -
                    (percentDiscount / 100) * (reviewInfo.totalValue - publishState.usedCredits) -
                    reusableFixedDiscount
                ),
          precision: 4,
          currency: userCompany.tpsRegion.currency,
        }).toFormat(),
    };

    allSlots = [...new Set(allSlots)].filter(el => el).sort((a, b) => a - b);
    let targetPlays = 0;

    const hasHiddenRestrictions = (schedules: any[] | undefined): boolean => {
      if (schedules) {
        for (const schedule of schedules) {
          for (const key in schedule.locations[0]) {
            if (key === 'hiddenRestriction') {
              return true;
            }
          }
        }
      }
      return false;
    };

    const campaignData = {
      campaignId: publishState.uuid,
      withAnalytics: publishState.category === 'Standard' || publishState.category === 'Managed' ? true : false,
      name: publishState.name,
      category: publishState.category,
      parentId: publishState.parentId,
      adomain: publishState.adomain,
      totalScreens: publishState.locations?.length,
      approval: 'regular',
      type: 'screen',
      createdOn: format(new Date(), 'yyyy-MM-dd HH:mm'),
      startDate: allSlots[0] * 1000,
      endDate: allSlots[allSlots.length - 1] * 1000,
      budget: budget,
      totalRunTime: state.totalRunTime,

      hasHiddenRestrictions: hasHiddenRestrictions(publishState.schedule),
      reviewPricing: reviewInfo,
      totalWithCalcApplied:
        reviewInfo &&
        Dinero({
          amount:
            Math.ceil(reviewInfo.totalValue - publishState.usedCredits) < 0
              ? 0
              : Math.ceil(
                  reviewInfo.totalValue -
                    publishState.usedCredits -
                    (percentDiscount / 100) * (reviewInfo.totalValue - publishState.usedCredits) -
                    reusableFixedDiscount
                ),
          precision: 4,
          currency: userCompany.tpsRegion.currency,
        }).toFormat(),
      reach: totalReach,
      industryName: publishState.industry,
      industry: publishState.industry,
      targetReach: numeral(totalReach).format('0,0'),
      currency: User ? User.tpsRegion.currency : 'USD',
      credits: publishState.credit / 100,
      coupon: publishState.coupon,
      formats: publishState.formats?.map(formatData => {
        return {
          duration: formatData.selectedDuration,
          ratio: formatData.ratio,
          contentType: formatData.contentType,
          resolution: `${formatData.resolution[0]}x${formatData.resolution[1]}`,
          uid: formatData.uuid,
          locations: formatData.locations,
          media: formatData.media.map(el => {
            return {
              contentType: formatData.contentType,
              duration: formatData.selectedDuration,
              ...el,
              rules: el.rules.map(rule => {
                return {
                  ...rule,
                  categoryCode: rule.type,
                };
              }),
            };
          }),
          outputs: [],
        };
      }),
      schedules: publishState.schedule
        ?.filter(el => el.uuid !== 'global' && el.locations.length > 0)
        .map((schedule: IScheduler) => {
          return {
            uid: schedule.uuid,
            isActive: true,
            isGlobal: schedule.isGlobal,
            locations: schedule.locations.map(el => {
              if (publishState.locations) {
                const screenList = data;

                let [screen] = publishState.locations?.filter(l => l.location.uuid === el.uuid);
                let [screenPrice] = screenList?.filter(l => l.uuid === el.uuid);
                console.log('el', screen);
                if (screen) {
                  targetPlays += getSlots(screen) * (3600 / el.pph) * el.circuit;
                  return {
                    circuit: el.circuit,
                    name: el.name,

                    uuid: el.uuid,
                    acceptedDurations: el.acceptedDurations,
                    campaignFrequency: screen.pph,
                    tz: screen.location.tz || 'Europe/London',
                    pph: screen.pph,
                    pC: screen.location.pC,
                    rt: screen.location.rt,
                    frequency: 3600 / screen.pph,
                    nUid: screen.location.networkUuid || screen.location.network.uuid,
                    totalSlots: getSlots(screen),
                    plays: getSlots(screen) * (3600 / el.pph) * el.circuit,
                    reach: screen.reach,
                    pricing: screenPrice ? screenPrice.price : '0',
                  };
                }
              }
            }),
            restrictions: [],
            partialRestrictions: [],
            acceptedDurations: schedule.locations[0].acceptedDurations,
            scheduleLocations: schedule.locations.length,
            slots: schedule.slots
              .map(slot => {
                const slotsDay = DateTime.fromMillis((slot * 1000) as number, { zone: 'utc', locale: 'utc' })
                  .toFormat('EEEE')
                  .toUpperCase();

                const slotsHour = DateTime.fromMillis((slot * 1000) as number, { zone: 'utc', locale: 'utc' })
                  .toFormat('EEEE_H')
                  .toUpperCase();
                let isFound = false;
                schedule.locations[0].restriction?.forEach(res => {
                  if (slotsDay.indexOf(res.day) > -1) {
                    res.generatedPrice.forEach((hour, index) => {
                      if (slotsHour === `${res.day + '_' + index}` && hour === '0') {
                        isFound = true;
                      }
                    });
                  }
                });

                if (isFound) {
                  return -1;
                }
                return slot * 1000;
              })
              .filter(el => el !== -1),
            type: schedule.type,
          };
        }),
      targetPlays,
    };
    formData.set('campaignData', JSON.stringify(campaignData));
    return formData;
  };

  const getSlots = (item: IListLocation): number => {
    if (publishState.schedule)
      for (const schedule of publishState.schedule) {
        for (const location of schedule.locations) {
          if (location.uuid === item.location.uuid) {
            const slotsDay = schedule.slots.map(slot => {
              return DateTime.fromMillis((slot * 1000) as number, { zone: 'utc', locale: 'utc' })
                .toFormat('EEEE')
                .toUpperCase();
            });
            const slotsHour = schedule.slots.map(slot => {
              return DateTime.fromMillis((slot * 1000) as number, { zone: 'utc', locale: 'utc' })
                .toFormat('EEEE_H')
                .toUpperCase();
            });
            let totalWithoutPlays = 0;
            schedule.locations[0].restriction?.forEach(res => {
              if (slotsDay.indexOf(res.day) > -1) {
                res.generatedPrice.forEach((hour, index) => {
                  const foundSlot = slotsHour.indexOf(res.day + '_' + index);
                  if (foundSlot !== null && foundSlot > -1 && hour === '0') {
                    totalWithoutPlays += 1;
                  }
                });
              }
            });
            return schedule.slots.length - totalWithoutPlays;
          }
        }
      }
    return 0;
  };
  const downloadCsv = async () => {
    setLoadingCsv(true);
    if (loadingCsv === true) {
      return false;
    }
    const submissionData = await submisionData();
    const mediaPlanBlobCsv: any = await Campaigns.getCsv(submissionData);
    const url = window.URL.createObjectURL(new Blob([mediaPlanBlobCsv]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${publishState.name} Campaign Media Plan by Blindspot.csv`); //or any other extension
    document.body.appendChild(link);
    link.click();
    setLoadingCsv(false);
  };

  const shareCampaign = async () => {
    setLoadingShareCampaign(true);
    if (loadingShareCampaign === true) {
      return false;
    }
    const submissionData = await submisionData();
    const id = JSON.parse(String(submissionData.get('campaignData'))).campaignId;
    const response = await Campaigns.saveMediaPlan(submissionData);

    setLoadingShareCampaign(false);
    if (response) {
      const trackUserData = await fetchUserClientData(user);
      const trackMediaPlanData = {
        uuid: id,
        type: 'media',
        eventType: 'create',
        eventData: {
          action: 'Created media plan',
          ...trackUserData,
        },
        isCampaign: false,
      };
      const trackResponse = await Campaigns.trackMediaPlan(trackMediaPlanData);

      const link = document.createElement('a');
      link.href = '/media-plan/' + id;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
    }
  };

  const downloadPdf = async () => {
    setLoadingPdf(true);
    if (loadingPdf === true) {
      return false;
    }
    const submissionData = await submisionData();
    const mediaPlanBlobCsv: any = await Campaigns.getPdf(submissionData);
    setLoadingPdf(false);
    const url = window.URL.createObjectURL(new Blob([mediaPlanBlobCsv]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${publishState.name} Campaign Media Plan by Blindspot.pdf`); //or any other extension
    document.body.appendChild(link);
    link.click();
  };
  const primaryButton: PrimaryButtonProps = {
    buttonText: 'Publish Campaign',
    action: async () => {
      primaryButtonAction();
    },
  };

  const primaryButtonAction = async () => {
    if (loadingSaveCampaign) return false;
    setLoadingSaveCampaign(true);
    const submissionData = await submisionData();
    const withAnalytics = JSON.parse(String(submissionData.get('campaignData'))).withAnalytics;
    try {
      const response = await Campaigns.saveCampaign(submissionData);
      if (response.errorMessage) {
        if (response.errorMessage.message === 'your campaign is beeing published.') {
          notify(
            response.errorMessage.message.charAt(0).toUpperCase() + response.errorMessage.message.slice(1),
            'warning'
          );
        } else {
          notify(response.errorMessage.message, 'error');
        }
      } else {
        if (reviewInfo) {
          const discountValue = (percentDiscount / 100) * (reviewInfo.totalValue - publishState.usedCredits);
          if (withAnalytics) {
            gtmPurchase(
              publishState,
              data,
              user ? user.tpsRegion.currency : 'USD',
              reviewInfo.totalValue,
              JSON.parse(String(submissionData.get('campaignData'))).budget.tax,
              percentDiscount,
              discountValue
            );
          }
        }
        notify('Campaign submitted successfuly', 'success');
        localStorage.removeItem('searchMaxPrice');
        localStorage.removeItem('searchMinPrice');
        localStorage.removeItem('searchText');
        localStorage.removeItem('searchCheckbox');
        localStorage.removeItem('searchTags');
        localStorage.removeItem('coming_soon');
        localStorage.removeItem('only_book');
        localStorage.removeItem('has_image');
        router.push('/mycampaigns');
      }
    } catch (error: any) {
      notify(error.message, 'info');
      setLoadingSaveCampaign(false);
      setShowDialog(false);
    }
  };

  const secondaryButton: PrimaryButtonProps = {
    buttonText: 'Cancel',
    action: () => {
      setShowDialog(false);
    },
  };

  const thirdButton: PrimaryButtonProps = {
    buttonText: 'Publish WITHOUT Analytics',
    action: () => {
      primaryButtonAction();
    },
  };

  const primaryButtonNewUser: PrimaryButtonProps = {
    buttonText: 'Provide details',
    action: () => {
      window.open('/profile/organization', '_blank');
      setShowDialogNewUser(false);
    },
  };
  const secondaryButtonNewUser: PrimaryButtonProps = {
    buttonText: 'Cancel',
    action: () => {
      setShowDialogNewUser(false);
    },
  };
  const useCredits = (val: any) => {
    const subTotalValue = reviewInfo ? reviewInfo.subTotalValue - reusableFixedDiscount : 0;
    const maxValueRangeSlider = credits < subTotalValue ? credits : subTotalValue;
    const domNode = rangeRef.current;
    if (domNode) {
      domNode.style.background = `linear-gradient(90deg, #f53d3f 0%, #f53d3f ${
        (val / maxValueRangeSlider) * 10000 * 100
      }%,
      #dedede ${(val / maxValueRangeSlider) * 10000 * 100}% ,#dedede 100%)`;
    }
    setCreditValue(val);
    try {
      let credit = val;
      let usedCredits = 0;

      credit = Math.ceil(Number(credit) * 10000);
      let vatCredits = 0;
      usedCredits = credit;

      if (usedCredits >= credits) {
        if (usedCredits > credits) {
          if (domNode) {
            domNode.style.background = `linear-gradient(90deg, #f53d3f 0%, #f53d3f ${
              (Math.round(credit / 100) / 100 / maxValueRangeSlider) * 10000 * 100
            }%, #dedede ${(Math.round(credit / 100) / 100 / maxValueRangeSlider) * 10000 * 100}% ,#dedede 100%)`;
          }
          setCreditValue(Math.round(credits / 100) / 100);
          vatCredits = 0;
          throw new Error(
            `The credits value must be less than or equal to ${Dinero({
              amount: credits,
              precision: 4,
              currency: userCompany.tpsRegion.currency,
            }).toFormat()}`
          );
        }
        if (usedCredits < 0) {
          throw new Error('The value must be greater then zero');
        }

        if (reviewInfo && reviewInfo.subTotalValue - credit - reusableFixedDiscount < 0) {
          credit = reviewInfo.subTotalValue;

          usedCredits = 0;
          vatCredits = 0;

          usedCredits = credit + vatCredits;

          notify(
            `You can only use ${Dinero({
              amount: reviewInfo.subTotalValue,
              precision: 4,
              currency: userCompany.tpsRegion.currency,
            }).toFormat()} of credits for this campaign`,
            'info'
          );
        }
        if (domNode) {
          domNode.style.background = `linear-gradient(90deg, #f53d3f 0%, #f53d3f ${
            (Math.round(credit / 100) / 100 / maxValueRangeSlider) * 10000 * 100
          }%, #dedede ${(Math.round(credit / 100) / 100 / maxValueRangeSlider) * 10000 * 100}% ,#dedede 100%)`;
        }
        setCreditValue(Math.round(credit / 100) / 100);
        dispatch({
          type: IPublishType.setCredit,
          payload: {
            usedCredits: usedCredits,
            vatCredits: vatCredits,
            credit: credit,
          },
        });
      } else {
        if (domNode) {
          domNode.style.background = `linear-gradient(90deg, #f53d3f 0%, #f53d3f ${
            (Math.round(credit / 100) / 100 / maxValueRangeSlider) * 10000 * 100
          }%, #dedede ${(Math.round(credit / 100) / 100 / maxValueRangeSlider) * 10000 * 100}% ,#dedede 100%)`;
        }
        setCreditValue(Math.round(credit / 100) / 100);
        dispatch({
          type: IPublishType.setCredit,
          payload: {
            usedCredits: usedCredits,
            vatCredits: vatCredits,
            credit: credit,
          },
        });
      }
    } catch (err: any) {
      notify(err.message, 'info');
    }
  };

  const onValidateVoucher = (voucherValueParam: number, voucherCodeParam: string, voucherTypeParam?: string) => {
    if (voucherValueParam !== 0) {
      dispatch({
        type: IPublishType.setPercentageVoucher,
        payload: {
          coupon: voucherCodeParam,
        },
      });
    } else {
      dispatch({
        type: IPublishType.removePercentageVoucher,
      });
    }
    setVoucherCode(voucherCodeParam);
    if (voucherTypeParam === 'percentage') {
      setPercentDiscount(voucherValueParam);
      setReusableFixedDiscount(0);
    } else {
      setReusableFixedDiscount(voucherValueParam);
      setPercentDiscount(0);
    }
  };

  const dineroValue =
    (reviewInfo?.totalValue || 0) -
    publishState.usedCredits -
    (percentDiscount / 100) * ((reviewInfo?.totalValue || 0) - publishState.usedCredits) -
    reusableFixedDiscount;

  return (
    <div className="z-10 page-section pb-14">
      {showDialog && (
        <Dialog
          key="1"
          title="Final Check"
          loading={loadingSaveCampaign}
          primaryButton={primaryButton}
          secondaryButton={secondaryButton}
          // thirdButton={JWTUser.role === 'admin' ? thirdButton : undefined}
          description={`
          You're about to create a campaign running in <strong>${totalLocations} locations</strong>, on <strong>${totalCircuit} screens</strong>, for a total of <strong>${totalHours} hours</strong>.<br /><Br />
          Your credit card will be charged <strong>${Dinero({
            amount: reviewInfo
              ? Math.ceil(
                  reviewInfo.totalValue -
                    publishState.usedCredits -
                    (percentDiscount / 100) * (reviewInfo.totalValue - publishState.usedCredits) -
                    reusableFixedDiscount
                ) < 0
                ? 0
                : Math.ceil(
                    reviewInfo.totalValue -
                      publishState.usedCredits -
                      (percentDiscount / 100) * (reviewInfo.totalValue - publishState.usedCredits) -
                      reusableFixedDiscount
                  )
              : 0,
            precision: 4,
            currency: userCompany.tpsRegion.currency,
          }).toFormat()}</strong> when the campaign is approved.
          ${
            JWTUser.role === 'admin'
              ? `<br><br> <strong>Attention!</strong> <br> <div style="display: flex; flex-direction: row; align-items: center; justify-content: center; width: 100%;"> This campaign is in the <strong style="color: red; margin-left: 5px; margin-right: 5px;">${publishState.category}</strong> category! 
          <div style="width: 20px;
          margin-left: 5px;
          background-color: #ff9800;
          color: #fff;
          border-radius: 100%;
          height: 20px;
          text-align: center;
          font-size: 14px;
          cursor: pointer;" data-tip data-for='categoryTooltip'>
          ?
          </div> </div>`
              : ''
          }
          `}
        />
      )}
      {showDialogNewUser && (
        <Dialog
          key="2"
          title={`Just one more step...`}
          primaryButton={primaryButtonNewUser}
          secondaryButton={secondaryButtonNewUser}
          description={`<strong>Hei! It seems like  we're missing some billing details required to publish your campaign. <br><br> Simply provide the necessary information, and you'll be all set to launch your campaign.</strong>`}
        />
      )}
      <div className="absolute top-0 left-0 right-0 flex items-center pl-4 pr-24 bg-white dark:bg-dark-200 dark:text-dark-400 md:hidden h-21 z-101 shadow-mob">
        Review your campaign
      </div>
      <div className="mt-20 md:mt-0">
        <BreadCrumbs />
        <div className="flex flex-col-reverse flex-wrap justify-between px-4 py-4 md:py-6 md:space-x-7 md:flex-row md:px-7 ">
          <div className="flex justify-between w-full md:space-x-7">
            <ClassicCard otherClasses="hidden md:flex-1 md:flex items-center justify-between h-21 md:h-16 rounded-20 mt-6 md:mt-0">
              <div className="text-sm font-semibold tracking-tighter text-dark-default dark:text-white px-7">
                Review your campaign PO #: TPS- {publishState.uuid.slice(0, 8).toUpperCase()}
              </div>
            </ClassicCard>
          </div>
        </div>
      </div>

      <div className="px-4 md:px-7">
        <div className="w-full">
          <div className={oneLine``}>
            {data.length > 0 ? (
              <div id="review-table" className="pb-4 md:pb-7">
                <div className="hidden overflow-hidden md:block rounded-t-3xl">
                  <DataTable
                    noHeader={true}
                    pagination={true}
                    paginationPerPage={15}
                    columns={columns}
                    data={data}
                    className="table-review"
                  />
                </div>

                <TableMobile header={columns} tableData={data} />
              </div>
            ) : (
              <div className="flex items-center w-full mx-auto">
                <img src="/images/loading.svg" className={` pointer-events-none w-40 block mx-auto `} />
              </div>
            )}
          </div>
          <div className="flex flex-col items-stretch justify-between space-y-4 md:flex-row md:space-y-0 md:space-x-7">
            <ClassicCard divId="review-download" otherClasses="flex-1 p-6 shadow-publishCard">
              <div className="flex flex-col items-center justify-center space-y-5">
                <img src="/images/review.svg" className="block dark:hidden" />
                <img src="/images/review-dark.svg" className="hidden dark:block" />
                <div className="font-medium text-center text-lightGrey dark:text-dark-300">
                  Before publishing your campaign, don’t forget to download your media plan as an overview of the
                  campaign
                </div>
                <div className="space-y-4">
                  <Button
                    fill="outlinedark"
                    loading={loadingCsv}
                    onClick={downloadCsv}
                    type="submit"
                    color="primary"
                    fullWidth={true}
                  >
                    {intl.formatMessage({
                      defaultMessage: 'Download media plan as csv',
                      id: 'Button.DownloadMediaPlanAsCsv',
                    })}
                  </Button>
                  <Button
                    fill="outlinedark"
                    loading={loadingPdf}
                    onClick={downloadPdf}
                    type="submit"
                    color="primary"
                    fullWidth={true}
                  >
                    {intl.formatMessage({
                      defaultMessage: 'Download media plan as pdf',
                      id: 'Button.DownloadMediaPlanAsPdf',
                    })}
                  </Button>
                  <Button
                    fill="outlinedark"
                    loading={loadingShareCampaign}
                    onClick={shareCampaign}
                    type="submit"
                    color="primary"
                    fullWidth={true}
                  >
                    Share Campaign Details
                  </Button>
                </div>
              </div>
            </ClassicCard>
            <ClassicCard divId="review-summary" otherClasses="flex-1 p-6 shadow-publishCard">
              <div className="w-full">
                <div className="w-full space-y-6 totals">
                  {reviewInfo && (
                    <>
                      <div className="text-lg font-bold dark:text-white">Your bill summary</div>
                      <ul className="pb-2 border-b review-steps border-bordercolor">
                        <li className="flex items-center justify-between">
                          <div className="text-sm font-bold text-dark-default dark:text-dark-400">Subtotal</div>
                          <div className="text-sm font-semibold dark:text-dark-400"> {reviewInfo.subTotal}</div>
                        </li>
                        <li className="flex items-center justify-between">
                          <div className="text-sm font-bold text-dark-default dark:text-dark-400">Tax</div>
                          <div className="text-sm font-semibold dark:text-dark-400">
                            {Dinero({
                              amount: Math.ceil(reviewInfo.vatValue) >= 0 ? Math.ceil(reviewInfo.vatValue) : 0,
                              precision: 4,
                              currency: userCompany.tpsRegion.currency,
                            }).toFormat()}
                          </div>
                        </li>
                        {reviewInfo.tlpValue !== 0 && (
                          <li className="flex items-center justify-between">
                            <div className="text-sm font-bold text-dark-default dark:text-dark-400">
                              Local Advertisement TAX
                            </div>
                            <div className="text-sm font-semibold dark:text-dark-400">{reviewInfo.tlp}</div>
                          </li>
                        )}
                        {reviewInfo && reviewInfo.handleFee !== 0 && (
                          <li className="flex items-center justify-between">
                            <div className="text-sm font-bold text-dark-default dark:text-dark-400">Handling fee</div>
                            <div className="text-sm font-semibold dark:text-dark-400">{reviewInfo.handleFeeVal}</div>
                          </li>
                        )}
                      </ul>
                      {(viewCredit !== '€0.00' && viewCredit !== '$0.00' && (
                        <>
                          <div className="flex items-center justify-center">
                            <div className="text-sm font-medium text-dark-default dark:text-dark-300">
                              You have <span className="text-sm font-bold"> {viewCredit}</span> worth in credits, use
                              them right here
                            </div>
                          </div>
                          <div className="pb-8 mt-4 border-b border-bordercolor range">
                            <RangeSlider
                              ref={rangeRef}
                              value={creditValue}
                              max={
                                credits < reviewInfo.subTotalValue - reusableFixedDiscount
                                  ? credits / 10000
                                  : (reviewInfo.subTotalValue - reusableFixedDiscount) / 10000
                              }
                              onChange={changeEvent => useCredits(parseInt(changeEvent.target.value))}
                              tooltip="auto"
                              tooltipPlacement="bottom"
                              variant="danger"
                              tooltipLabel={currentValue =>
                                `${userCompany.tpsRegion.currency === 'EUR' ? '€ ' : '$ '} ${currentValue}`
                              }
                              // onChange={changeEvent => setCreditValue(parseInt(changeEvent.target.value))}
                            />
                            <div className="flex items-center justify-between">
                              <div className="text-sm text-lightGrey dark:text-dark-400">€0</div>
                              <div className="text-sm text-lightGrey dark:text-dark-400">
                                {credits < reviewInfo.subTotalValue - reusableFixedDiscount
                                  ? viewCredit
                                  : reviewInfo.total}
                              </div>
                            </div>
                            {/* <Input
                          name="input1"
                          label="Use credits"
                          onChange={(e: any) => {
                            setCreditValue(e.target.value)
                          }}
                        />
                        <Button color="primary" fill="outline" onClick={() => useCredits()}>
                          Use Credit
                        </Button> */}
                          </div>
                        </>
                      )) ||
                        ''}

                      {publishState.credit !== 0 && (
                        <div className="flex items-center justify-between">
                          <div className="text-sm font-bold text-dark-default dark:text-dark-400">Used Credit</div>
                          <div className="text-sm font-semibold dark:text-dark-400">
                            {userCompany.tpsRegion &&
                              Dinero({
                                amount: publishState.credit,
                                precision: 4,
                                currency: userCompany.tpsRegion.currency,
                              }).toFormat()}
                          </div>
                        </div>
                      )}
                      {(percentDiscount !== 0 || reusableFixedDiscount !== 0) && (
                        <div className="flex items-center justify-between">
                          <div className="text-sm font-bold text-dark-default dark:text-dark-400">
                            Subtotal with used credit
                          </div>
                          <div className="text-sm font-semibold dark:text-dark-400">
                            {userCompany.tpsRegion &&
                              Dinero({
                                amount: reviewInfo.totalValue - publishState.usedCredits,
                                precision: 4,
                                currency: userCompany.tpsRegion.currency,
                              }).toFormat()}
                          </div>
                        </div>
                      )}
                      {(percentDiscount !== 0 || reusableFixedDiscount !== 0) && (
                        <div className="flex items-center justify-between">
                          <div className="text-sm font-bold text-dark-default dark:text-dark-400">
                            Discount voucher "{voucherCode}" ({' '}
                            {percentDiscount !== 0
                              ? percentDiscount
                              : Dinero({
                                  amount: Math.ceil(reusableFixedDiscount),
                                  precision: 4,
                                  currency: userCompany.tpsRegion.currency,
                                }).toFormat()}{' '}
                            {percentDiscount !== 0 ? '%' : ''}){' '}
                          </div>
                          <div className="text-sm font-semibold dark:text-dark-400">
                            -
                            {userCompany.tpsRegion && percentDiscount !== 0
                              ? Dinero({
                                  amount: Math.ceil(
                                    (percentDiscount / 100) * (reviewInfo.totalValue - publishState.usedCredits)
                                  ),
                                  precision: 4,
                                  currency: userCompany.tpsRegion.currency,
                                }).toFormat()
                              : Dinero({
                                  amount: Math.ceil(reusableFixedDiscount),
                                  precision: 4,
                                  currency: userCompany.tpsRegion.currency,
                                }).toFormat()}
                          </div>
                        </div>
                      )}
                      <div className="flex items-center justify-between">
                        <div className="text-md font-bold text-dark-default dark:text-dark-400">Total</div>
                        <div className="text-md font-semibold dark:text-dark-400">
                          {Dinero({
                            amount:
                              Math.ceil(reviewInfo.totalValue - publishState.usedCredits) < 0
                                ? 0
                                : Math.ceil(dineroValue),
                            precision: 4,
                            currency: userCompany.tpsRegion.currency,
                          }).toFormat()}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="flex flex-col items-center justify-center">
                    <div className="px-4 pb-2 pt-8 mb-8 border border-dashed border-lightGrey dark:border-bordercolordark rounded-xl w-full">
                      <AddVoucher
                        labelName="Discount code"
                        isPercentage={true}
                        clearErrorsTrigger={clearDiscountErrors}
                        campaignValue={reviewInfo?.totalValue}
                        onComplete={(voucherValueParam, voucherCodeParam, voucherTypeParam) => {
                          onValidateVoucher(voucherValueParam, voucherCodeParam, voucherTypeParam);
                        }}
                      />
                    </div>
                    <div className="relative" id="review-publish">
                      <Button
                        onClick={() => {
                          if (publishState.uuid === '1b5a9446-1e0e-498c-8bed-ee7e31e55b17') {
                            return;
                          }
                          setClearDiscountErrors(Math.random());
                          if (!user?.isNew) {
                            setShowDialog(true);
                          } else {
                            setShowDialogNewUser(true);
                          }
                        }}
                        type="submit"
                        color="primary"
                        fullWidth={false}
                        loading={loadingSaveCampaign}
                        // disabled={user?.isNew}
                      >
                        {intl.formatMessage({
                          defaultMessage: 'Publish your campaign',
                          id: 'Button.Publish',
                        })}
                      </Button>
                      {/* <div ref={bottomRef}></div> */}
                      {user?.isNew && (
                        <div className="flex justify-center">
                          <ReactTooltip id="profile-alert" place="left" className="custom-tooltip">
                            <div className="flex flex-col items-center justify-center preview-media-tooltip">
                              <div className="mb-1">Your account is not completed yet.</div>
                              <div>
                                You will not be able to publish a campaign until you complete your billing details.
                              </div>
                            </div>
                          </ReactTooltip>
                          <div
                            data-tip
                            data-for="profile-alert"
                            className="absolute cursor-pointer -top-2 -right-2 excl-mark-footer pulse-disclaimer"
                          >
                            ?
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <p className="text-xs text-center text-lightGrey dark:text-dark-300">
                    *Final number of ad plays and budget may vary slightly due to each location’s audience data.
                  </p>
                </div>
              </div>
            </ClassicCard>
          </div>
        </div>
      </div>
      <FooterMobile
        backFn={backFn}
        nextFn={() => {}}
        mainPage="Dashboard"
        links={[publishState.name]}
        back="1"
        next=""
        hasNext={false}
      />
    </div>
  );
};
export default ReviewPage;
