import React, { useCallback, useState } from 'react';
import { Stack } from 'components/layout';
import { ChevronLeft, ChevronRight, Download, Notification } from 'react-iconly';
import { useIntl, FormattedMessage } from 'react-intl';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Button } from '../atoms';
import { useApp } from 'components/app';
import { JWT } from '@api/users';
import { Campaigns } from '@api/campaigns';
import Popover from '../atoms/popover/popover';
import { CAMPAIGNS } from '@api/index';

type IProps = {
  title?: any;
  subtitle?: string;
  subtitleMob?: string;
  hideTitleOnMobile?: boolean;
  backBtn?: string;
  hideAddBtn?: boolean;
  showShareBtn?: boolean;
  copyClipboard?: boolean;
  hasLogo?: boolean;
  shareID?: string;
  hint?: boolean;
  campaign?: any;
  children?: React.ReactNode;
  loading?: boolean;
  bookLocation?: () => void;
};

const Navbar: React.FC<IProps> = ({
  children,
  title,
  subtitle,
  hideTitleOnMobile,
  subtitleMob,
  backBtn,
  hideAddBtn,
  showShareBtn = false,
  copyClipboard,
  shareID,
  bookLocation,
  hasLogo = false,
  hint,
  loading,
  campaign,
}: IProps) => {
  const hasNotifications = true;
  const hasUnreadNotifications = true;
  const ref = React.createRef();
  const router = useRouter();
  const { notify } = useApp();
  const [loadingPdf, setLoadingPdf] = React.useState<boolean>(false);
  const [loadingCsv, setLoadingCsv] = React.useState<boolean>(false);
  const onClickRedirect = () => {
    if (bookLocation) {
      bookLocation();
      return;
    }
    router.push('/publish/basic');
  };

  const onClickShare = () => {
    router.push('/media-plan/' + title);
  };

  const onClickHandle = () => {
    router.push(backBtn || '');
  };
  const onCopyToClipboard = () => {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = 'https://portal.seeblindspot.com/media-plan/' + shareID;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    notify('The link has been copied to clipboard!', 'success');
  };

  const downloadCsv = async () => {
    setLoadingCsv(true);
    if (loadingCsv === true) {
      return false;
    }
    const mediaPlanBlobCsv: any = await Campaigns.downloadCsv(campaign.uuid);
    const url = window.URL.createObjectURL(new Blob([mediaPlanBlobCsv]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${campaign.name} Campaign Media Plan by Blindspot.csv`); //or any other extension
    document.body.appendChild(link);
    link.click();
    setLoadingCsv(false);
  };

  const downloadPdf = async () => {
    setLoadingPdf(true);
    if (loadingPdf === true) {
      return false;
    }
    setLoadingPdf(false);
    const result: any = await CAMPAIGNS.downloadPdf(`${campaign.uuid}`);

    const url = window.URL.createObjectURL(new Blob([result]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${campaign.name} Campaign Media Plan by Blindspot.pdf`); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div
      className={`py-4 ${
        title === '' || hideTitleOnMobile ? 'navbar-right-padding' : ''
      } flex-shrink-0 tablet:border-b tablet:border-bordercolor dark:tablet:border-bordercolordark`}
    >
      <div
        className={`flex max-lg:flex-col flex-row h-full px-7 md:pr-7 ${hideTitleOnMobile ? '' : ''} ${
          !copyClipboard ? 'min-tablet:justify-between' : 'justify-between'
        }`}
      >
        <div className="flex flex-row">
          {backBtn && (
            <Button color="white" justIcon={true} className="mr-3 md:hidden" onClick={onClickHandle}>
              <div className="text-lightGrey dark:text-dark-400">
                <ChevronLeft set="light" />
              </div>
            </Button>
          )}
          <div className={'flex flex-col md:flex-row md:items-center'}>
            {hasLogo && (
              <div className="w-40 md:mr-10 mt-1">
                <img
                  src="/images/logo-blindspot.svg"
                  width="280"
                  height="115"
                  alt="Blindspot"
                  className="block dark:hidden"
                />
                <img
                  src="/images/logo-blindspot-darkmode.svg"
                  width="280"
                  height="115"
                  alt="Blindspot"
                  className="hidden dark:block"
                />
              </div>
            )}
            {title !== '' && (
              <div
                className={`flex flex-col md:py-4 md:flex-row md:items-center h-full ${
                  hideTitleOnMobile ? 'min-md:block hidden' : ''
                }`}
              >
                {title && (
                  <div
                    className="text-base text-lightGrey md:text-black md:text-opacity-60 md:text-xl blindspot-title dark:text-white"
                    dangerouslySetInnerHTML={{ __html: title }}
                  ></div>
                )}
                {subtitle && (
                  <div className="text-xl font-bold border-black text-dark-default md:font-semibold md:pl-3 md:ml-3 md:border-l dark:border-lightGrey border-opacity-60 blindspot-title dark:text-dark-400">
                    {subtitle}
                  </div>
                )}
                {subtitleMob && (
                  <div className="text-xl font-bold border-black text-dark-default md:font-semibold md:pl-3 md:ml-3 md:border-l dark:border-lightGrey border-opacity-60 md:hidden blindspot-title dark:text-dark-400">
                    {subtitleMob}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-row items-center justify-end">
          <div className={backBtn ? '' : children ? 'ml-auto' : ''}>
            {children ? (
              <div className="flex items-center justify-end ml-4">{children}</div>
            ) : (
              <div className="flex items-center justify-end">
                {/* {(hasNotifications && (
                <div className={`ico-notification mr-3 ${(hasUnreadNotifications && 'active') || ''}`}>
                  <Link href="/">
                    <Notification set="light" primaryColor="#858D96" size="medium" />
                  </Link>
                </div>
              )) ||
                ''} */}
                <div className={!copyClipboard ? 'hidden' : 'pr-4'}>
                  <Button className="whitespace-nowrap" onClick={onCopyToClipboard} tag="div">
                    Get Share Link
                  </Button>
                </div>

                {showShareBtn && (
                  <div className={!showShareBtn ? 'hidden' : 'pr-4'}>
                    <a target="_blank" href={'/media-plan/' + shareID} rel="noopener noreferrer">
                      <Button className="whitespace-nowrap" tag="div">
                        Share Campaign
                      </Button>
                    </a>
                  </div>
                )}

                {hint && (
                  <div className="flex flex-row items-center navbar-hint">
                    <p className="mr-4 text-sm dark:text-dark-300 text-dark-200">browse over 1 Million billboards</p>
                    <img className="translate-y-[1rem] mr-4 w-14" src="/images/arrow-referral.svg" />
                  </div>
                )}
                <div className={`${hideAddBtn ? 'hidden' : hasLogo ? 'hidden min-tablet:block' : ''}`}>
                  <Button loading={loading} onClick={onClickRedirect} tag="div" className="whitespace-nowrap">
                    Create campaign
                  </Button>
                </div>

                {/* {campaign && (
                  <div className={!campaign ? 'hidden' : 'pr-4'}>

                      <Button
                        tag="div" fill="outlinedark"
                        loading={false}
                        onClick={downloadCsv}
                        type="submit"
                        color="primary"
                        fullWidth={true}
                      >
                        Download Media Plan As PDF
                      </Button>
                  </div>
                )} */}
              </div>
            )}
          </div>

          {campaign && (
            <div className={!campaign ? 'hidden' : 'flex flex-row justify-end'}>
              <Popover
                className="rightbottom"
                handler={
                  <>
                    <Button
                      tag="div"
                      // fill="outlinedark"
                      loading={false}
                      type="submit"
                      color="primary"
                      fullWidth={true}
                    >
                      <div className="mr-2">Download</div>
                      <div className="">
                        <Download set="light" primaryColor="#ffffff" size="medium" />
                      </div>
                    </Button>
                  </>
                }
                content={
                  <div>
                    <div className="flex flex-col p-2 space-y-4 whitespace-nowrap">
                      <Button fill="outlinedark" onClick={downloadPdf} type="submit" color="primary" fullWidth={true}>
                        Download as PDF
                      </Button>
                      <Button fill="outlinedark" onClick={downloadCsv} type="submit" color="primary" fullWidth={true}>
                        Download as CSV
                      </Button>
                    </div>
                  </div>
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Navbar;
