import React, { useCallback, useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useIntl, FormattedMessage } from 'react-intl';

import { PublishContext } from 'context/publish/publish.provider';
import { MapContext } from 'context/publish/map.provider';

import { useRouter } from 'next/router';

import { BreadCrumbs, FooterMobile, PublishPphSelect } from 'components/common/molecules';

const PlayRate: React.FC = () => {
  const intl = useIntl();
  const router = useRouter();
  const [searchText, setSearchText] = React.useState<string>('');
  const [locationType, setLocationType] = React.useState<string[]>([]);

  const ctx = {
    searchText,
    setSearchText,
    locationType,
    setLocationType,
  };

  const { publishState, dispatch } = React.useContext(PublishContext);
  const backFn = () => {
    router.push('/publish/select');
  };
  const nextFn = () => {
    if (publishState.locations && publishState.locations?.length > 0) router.push('/publish/schedule');
  };
  useEffect(() => {
    if (!publishState.locations || publishState.locations?.length === 0) {
      router.push('/publish/select');
    }
    return () => {};
  }, []);
  return (
    <div className="relative pt-24 z-10">
      {/* <BreadCrumbs backFn={backFn} nextFn={nextFn} mainPage="Dashboard" links={[publishState.name]} back="1" next="1" hasNext={true} /> */}
      <div className="flex w-11/12 md:w-120 mx-auto pt-12 text-xl text-center flex-col space-y-8">
        <h4 className="text-white w-full text-center font-semibold ">
          <FormattedMessage id="Text.SelectPlaceholder" />
        </h4>
        <div>
          {publishState.pph &&
            publishState.pph.map((el, index) => {
              return <PublishPphSelect pph={el} key={index} index={index} />;
            })}
          qwe
        </div>
      </div>
      <FooterMobile
        backFn={backFn}
        nextFn={nextFn}
        mainPage="Dashboard"
        links={[publishState.name]}
        back="1"
        next="1"
        hasNext={true}
      />
    </div>
  );
};
export default PlayRate;
